import React from 'react';
import ErrorDisplay from './ErrorDisplay';
import {Trans, t} from '@lingui/macro';
import withDataHOC from '../dataProvider/withDataHOC';
import { GLOBAL_DATA } from '../../constants/globalData';

/**
 * @fero
 */

class Page404 extends React.PureComponent {
    
    render() {
        const error = this.props[GLOBAL_DATA.ERROR];
        return <div className="text-center p-5">
            <h1>404</h1>
            <h1><Trans>Stánka, ktorú ste zadali, neexistuje.</Trans></h1>
            <span><Trans>Ak si myslíte, že by mala, kontaktujte, prosím, administrátora.</Trans></span>
            <ErrorDisplay error={error}/>
        </div>;
    }
}

export default withDataHOC([GLOBAL_DATA.ERROR])(Page404);